<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @submit.native.prevent.stop="handleSubmit"
      label-width="80px"
    >
      <el-form-item label="ID" prop="id" v-if="dataForm.id">
        <el-input
          v-model="dataForm.id"
          :disabled="true"
          placeholder="ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type" class="fullList">
        <el-radio-group v-model="type" :disabled="disabled">
          <el-radio
            v-for="(type, index) in typeList"
            :key="index"
            :label="index"
          >
            {{ type }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上级店铺" prop="parentId" v-if="type === 1">
        <el-select
          v-model="dataForm.parentId"
          :disabled="disabled"
          placeholder="上级店铺名称"
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="店铺名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="店铺名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="creditCode">
        <el-input
          v-model="dataForm.creditCode"
          :disabled="disabled"
          placeholder="统一社会信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input
          v-model="dataForm.contact"
          :disabled="disabled"
          placeholder="联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactMobile">
        <el-input
          v-model="dataForm.contactMobile"
          :disabled="disabled"
          placeholder="联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否使用" prop="isUse">
        <el-checkbox v-model="dataForm.isUse">是否使用</el-checkbox>
      </el-form-item>
      <el-button size="small" @click="$dialog('certificateAddOrUpdate')"
        >上传证件</el-button
      >
      <el-table :data="dataForm.certificateList" border style="width: 100%">
        <el-table-column header-align="center" align="center" label="证件名称">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.name"
              placeholder="证件名称"
              :disabled="disabled"
            />
          </template>
        </el-table-column>

        <el-table-column header-align="center" align="center" label="预览图">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.url"
              fit="cover"
              @click="openImg(scope.row.url)"
            />
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="到期日">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.expiryDate"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :disabled="disabled"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label="地址" prop="address" class="inlineBtn">
        <el-input
          v-model="dataForm.address"
          :disabled="disabled"
          placeholder="地址"
          @change="tranAddress"
        >
        </el-input>
        <div class="mapBlock">
          <el-button @click="openForm" type="info" v-if="!chooseVisible"
            >打开地图</el-button
          >
          <el-button @click="closeForm" type="warning" v-else
            >关闭地图</el-button
          >
          <map-choose
            ref="mapchoose"
            v-if="chooseVisible"
            @newAddress="getNewAddress"
            class="mapWay"
          />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
    <certificate-add-or-update
      v-if="certificateAddOrUpdateVisible"
      ref="certificateAddOrUpdate"
      @success="getCertificate"
    ></certificate-add-or-update>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import CertificateAddOrUpdate from './certificate-add-or-update';
import mapChoose from '@/components/addressChoose';
import { getOrgList } from '@/utils/options';
import { keys, pick } from 'lodash';
export default {
  mixins: [normal],
  components: {
    CertificateAddOrUpdate,
    mapChoose,
  },
  data() {
    return {
      disabled: false,
      certificateAddOrUpdateVisible: false,
      visible: false,
      chooseVisible: false,
      markersLayer: null,
      geometries: null,
      dataForm: {
        id: 0,
        parentId: '',
        name: '',
        isUse: true,
        creditCode: '',
        contact: '',
        contactMobile: '',
        address: '',
        longitude: null,
        latitude: null,
        certificateList: [],
      },
      type: 0,
      orgList: [],
      typeList: ['总店铺', '分店铺'],
      dataRule: {
        name: [
          { required: true, message: '供应商名字不能为空', trigger: 'blur' },
        ],
        parentId: [
          { required: true, message: '上级店铺名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getOrgList();
  },
  methods: {
    handleSubmit() {
      return false;
    },
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.certificateList = [];
      this.visible = true;
      this.dataForm.parentId = '';
      this.openForm();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/cc/org/id/query/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = pick(data.data, keys(this.dataForm));
              if (
                this.dataForm.parentId !== 0 &&
                this.dataForm.parentId !== null
              ) {
                this.type = 1;
              }
            }
          });
        }
      });
    },
    openForm() {
      this.chooseVisible = true;
      this.$nextTick(() => {
        if (this.dataForm.address) {
          this.$refs.mapchoose.getAddress(this.dataForm.address);
        } else {
          this.$refs.mapchoose.getAddress('佛山市');
        }
      });
    },
    closeForm() {
      this.chooseVisible = false;
    },
    getNewAddress(address) {
      this.dataForm.address = address;
      this.tranAddress(address);
    },
    async tranAddress(data) {
      if (this.chooseVisible) {
        const address = await this.$refs.mapchoose.getAddress(data);
        if (address !== null) {
          this.dataForm.latitude = address.latitude;
          this.dataForm.longitude = address.longitude;
        }
      }
    },
    getCertificate(row) {
      this.dataForm.certificateList.push(...row);
    },
    deleteHandle(id) {
      this.dataForm.certificateList = this.dataForm.certificateList.filter(
        (i) => i.id !== id,
      );
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.type === 0) {
        this.dataForm.parentId = null;
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/org/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
